import theLogo from './theLogo300x200.png';

const ProjectLogo = () => {

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <img src={theLogo} alt="EV" />
            </div>
            <div>
            </div>
        </div>
    );

}

export default ProjectLogo;
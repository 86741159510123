import React from "react";
import ReactDOM from "react-dom";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import ProjectLogo from './common/components/ProjectLogo/ProjectLogo';
import Receipts from './Receipts';
import config from './config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TrustAcceptanceProvider from "./contexts/TrustAcceptanceContext";

import './index.scss';
import "./styles/index.scss";


if (process.env.NODE_ENV === 'development') {
  // const { worker } = require('./mocks/browser')
  // worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <TrustAcceptanceProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={config.recaptcha.sitekey}
      >
        <div className="header">
          <ProjectLogo />
        </div>
        <Receipts />
      </GoogleReCaptchaProvider>
    </TrustAcceptanceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


import "./styles.scss"


export interface ErrorMessageProp {
    title: string;
    subTitle?: string | JSX.Element;
    description: string;
}

const ErrorMessage: React.FC<ErrorMessageProp> = ({ title, subTitle, description }) => {
    return <div className="error-message-section">
        <div>
            <h2 className="error-message-message">
                <div>{title}</div>
                {subTitle && <div>{subTitle}</div>}
            </h2>
            <p className="error-message-hint">{description}</p>
        </div>
    </div>
}

export default ErrorMessage;
import { AddToStorageIfAbsent, RetrieveFromStorage } from "./AddToStorageIfAbsent";


const TRUST_KEY = "EV_ReceiptApp_TrustDialogStatus";

export function StoreTrustDialogAcceptanceStatusIfAbsent(value: string) {
    AddToStorageIfAbsent(TRUST_KEY, value);
}

export function ExtractTrustDialogAcceptanceStatus() {
    var cookieStatus = RetrieveFromStorage(TRUST_KEY);

    if (cookieStatus.length === 0)
        return;

    return cookieStatus.toUpperCase();
}

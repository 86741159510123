import { Text } from "drax-design-system";
import React from "react";
import ReactSelect from "react-select";
import "./styles.scss";
import { simpleSelectStyles } from "./styles";
import { ISelectOption } from "./types";

interface ISimpleSelect {
  label?: string;
  options: ISelectOption[];
  placeholder?: string;
  icon?: string;
  value: ISelectOption;
  isClearable?: boolean;
  isDisabled?: boolean;
  onSelectChange(el: ISelectOption): void;
}

const SimpleSelect: React.FC<ISimpleSelect> = ({
  value,
  options,
  label,
  placeholder,
  isClearable,
  isDisabled,
  onSelectChange,
  ...props
}) => (
  <div className={`p-simple-select ${isDisabled ? 'p-simple-select--disabled' : ''}`} {...props}>
    {!!label && (
      <Text textColor="tertiary" className="p-simple-select__label">
        {label}
      </Text>
    )}
    <ReactSelect
      value={value}
      styles={simpleSelectStyles}
      options={options}
      isDisabled={isDisabled}
      placeholder={placeholder}
      autoFocus={false}
      onChange={onSelectChange as any}
      isClearable={isClearable}
      blurInputOnSelect={true}
    />
  </div>
);

export default SimpleSelect;

import React, { useState } from "react";
import ReactSelect, { components, InputActionMeta, } from "react-select";
import { CustomMenu } from "./CustomMenu";
import { CustomSingleValue } from "./CustomSingleValue";
import { SearchIcon } from "./SearchIcon";
import { customStyles } from "./styles";
import "./styles.scss";
import { ISelectOption } from "./types";

const { Input } = components;

interface ISelect {
  value: ISelectOption | null,
  label?: string;
  options: ISelectOption[];
  placeholder?: string;
  icon?: string;
  autoFocus: boolean;
  showIcon: boolean;
  isClearable: boolean;
  onSelectChange: (el: ISelectOption) => void;
  onInputChanged: (searchValue: string) => void;
  onMenuOpenToggle?: (isOpen: boolean) => void;
}


const CustomInput = (props: any) => {
  return <Input {...props} maxLength={4} />;
};

export const Select: React.FC<ISelect> = ({
  value,
  autoFocus,
  options,
  label,
  placeholder,
  showIcon,
  isClearable,
  onSelectChange,
  onInputChanged,
  ...props
}) => {

  const [focused, setFocused] = useState(false)

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      onInputChanged(inputText);
    }

  }

  return (<div className="p-select" {...props}>
    <div className="div-left">
      <SearchIcon />
    </div>
    <div>
      <ReactSelect
        styles={customStyles}
        value={value}
        components={{
          Input: CustomInput,
          SingleValue: CustomSingleValue,
          Menu: CustomMenu,
          IndicatorSeparator: () => null,
        }}
        options={options}
        onMenuOpen={() => props.onMenuOpenToggle?.(true)}
        onMenuClose={() => props.onMenuOpenToggle?.(false)}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={onSelectChange as any}
        isClearable={isClearable}
        blurInputOnSelect={true}
        filterOption={() => true}
        onInputChange={handleInputChange}
        onKeyDown={(event: any) => {
          if (!(event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        //@ts-ignore
        isFocused={focused}
      />
    </div>
  </div>
  );

};

export default Select;
import Overlay from "../Overlay/Overlay";

import { useContext, useEffect } from "react";
import { Button } from 'drax-design-system';
import "./styles.scss"
import { TrustAcceptanceContext, TrustStatus } from "../../contexts/TrustAcceptanceContext";
import useBlurWhenOverlap from "../../common/hooks/useBlurWhenOverlap";
import { Events, sendEvent } from "../../common/functions/hjEvent";


const TrustDialog = () => {
    const { status, setStatus } = useContext(TrustAcceptanceContext);
    const isOpen = status == null;
    const dialogRef = useBlurWhenOverlap();


    useEffect(() => {
        if (isOpen) {
            sendEvent(Events.TRUST_CONSENT_SHOW);
        }
    }, [isOpen])

    return (
        <Overlay open={isOpen}>
            <div className="dialog" ref={dialogRef}>
                <h2>Trust this Computer?</h2>
                <div>If you click yes, the last 4 digits of the payment card entered will be remembered in the drop down for future searches.</div>
                <Button className="button white" onClick={() => {
                    sendEvent(Events.TRUST_CONSENT_NO);
                    setStatus(TrustStatus.DENIED)
                }}>
                    No
                </Button>
                <Button className="button" onClick={() => {
                    sendEvent(Events.TRUST_CONSENT_YES);
                    setStatus(TrustStatus.ACCEPTED)
                }}>
                    Yes
                </Button>
            </div>
        </Overlay>
    )
}

export default TrustDialog;
import ErrorMessage from './ErrorMessage';
import RateLimitingErrorMessage from './RateLimiting/RateLimitingErrorMessage';
import { isRetryError, ResponseError } from './ReceiptsLst/types';

export interface ErrorContainerProps {
  error: ResponseError | null,
  clearError: () => void;
}

export const ErrorContainer: React.FC<ErrorContainerProps> = ({ error, clearError }) => {

  if (error == null) {
    return null;
  }

  if (isRetryError(error)) {
    return <RateLimitingErrorMessage retryAfter={error.retryAfter} onExpire={clearError} />
  }

  return <ErrorMessage
    title="Error with retreiving data"
    description="Please reload the page or make another selection. If the issue persists, please try again later"
  />;
}

export default ErrorContainer


import config from "../../config";

export const downloadReceipt = async (id: string, token: string) => {

    const result = await fetch(
        `${config.api.domain.chargeTransactions}/getReceipt/${id}`,
        {
            mode: 'cors',
            headers: {
                'X-Recaptcha-Token': token,
            }
        })

    if (!result.ok) {
        throw result;
    }

    const header = result.headers.get('Content-Disposition');
    let filename = `receipt_${id}`;
    if (header != null) {
        const parts = header.split(';');
        filename = parts[1].split('=')[1].replaceAll("\"", "");
    }

    const blob = await result.blob();

    if (blob != null) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        await dalay(50);
        a.remove();
    }
}

const dalay = (ms: number) => new Promise(resolve => {
    setTimeout(resolve, ms);
});

const runtimeConfig: any = (window as any).runtimeEnv ?? {};

const config = {

  api: {
    domain: {
      chargeTransactions: "api/chargetransactions",
    },
  },
  app: {
    pageSize: 50,
  },
  recaptcha: {
    sitekey: runtimeConfig['REACT_APP_RECAPTCHA_SITE_KEY'] as string,
  },
  version: process.env.REACT_APP_VERSION_INFO,
};
export default config;

import { ReactNode } from 'react';
import "./styles.scss"

interface OverlayProps {
    open: boolean;
    children?: ReactNode;
}

function Overlay({ open, children }: OverlayProps) {
    return (
        <div className="overlay-wrapper" hidden={!open}>
            {children}
        </div>
    );
}

export default Overlay;

import { Button } from 'drax-design-system';
import React, { useCallback, useState } from 'react'
import { Events, sendDownloadEvent, sendEvent } from '../../common/functions/hjEvent';

const ReceiptsDownload: React.FC<{ ids: string[], onDownload: (ids: string[]) => Promise<any> }> = ({ ids, onDownload }) => {

    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = useCallback(async () => {
        setIsDownloading(true);
        sendEvent(Events.RECEIPT_DOWNLOAD_CLICK);
        sendDownloadEvent(ids.length)
        await onDownload([...ids]);
        setIsDownloading(false);
    }, [ids, onDownload, setIsDownloading]);

    const hasSelected = ids.length > 0;
    return <Button
        style={{ minWidth: 200, width: 200 }}
        title={`Download${hasSelected ? ` x${ids.length} receipt` : ''}`}
        disabled={!hasSelected || isDownloading}
        onClick={handleDownload} />

}

export default ReceiptsDownload;
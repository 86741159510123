export function AddToStorageIfAbsent(key: string, value: string)
{
    if (typeof window !== 'undefined') {
        //on the browser
        localStorage.setItem(key, value);
    } 
}

export function RetrieveFromStorage(key: string)
{
    if (typeof window !== 'undefined') {
        //on the browser
        return localStorage.getItem(key) ?? "";
    }

    return "";

}
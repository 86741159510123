import { useTimer } from "react-timer-hook";
import ErrorMessage from "../ErrorMessage";
import "./styles.scss"

type RateLimitingErrorMessageProps = {
    retryAfter: number;
    onExpire: () => void;
}

const RateLimitingErrorMessage = ({ retryAfter, onExpire }: RateLimitingErrorMessageProps) => {
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + retryAfter);

    const { seconds, minutes } = useTimer({
        expiryTimestamp,
        onExpire: onExpire
    });

    return (
        <ErrorMessage
            title="Rate limit is exceeded."
            subTitle={<>Try again in {!!minutes && (minutes + " minutes and")} <span className="try-again-second">{seconds}</span> seconds</>}
            description="You have exceeded the amount of receipts available to download."
        />

    );
}

export default RateLimitingErrorMessage;
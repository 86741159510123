import { Events, sendEvent } from "../../common/functions/hjEvent";
import { ResponseError } from "./types";

export const getErrorFromResponse = async (response: Response): Promise<ResponseError> => {
    const { status } = response;

    let message = "";
    try {
        const body = await response.json();
        message = body?.message ?? "";
    } catch { } finally { }

    switch (status) {
        case 422: {
            sendEvent(Events.ERROR_BOT);
            break;
        }
        case 429: {
            const retryAfter = parseInt(response.headers.get('retry-after') ?? '');
            sendEvent(Events.ERROR_RATE_LIMIT);
            return {
                status,
                retryAfter: retryAfter === 0 || isNaN(retryAfter) ? 60 : retryAfter,
            } as ResponseError;
        }
        default: {
            sendEvent(Events.ERROR_GENERAL);
        }
    }

    return { status, message }
}
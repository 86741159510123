import { createContext, useCallback, useState } from "react";
import { ExtractTrustDialogAcceptanceStatus, StoreTrustDialogAcceptanceStatusIfAbsent } from "../common/functions/SessionStorage/TrustAcceptanceStorage";

export enum TrustStatus {
    ACCEPTED = "ACCEPTED",
    DENIED = "DENIED",
}

interface TrustAcceptance {
    status?: TrustStatus,
    setStatus: (status: TrustStatus) => void;
}


export const TrustAcceptanceContext = createContext<TrustAcceptance>({ setStatus: () => void (0) });

export const TrustAcceptanceProvider: React.FC = ({ children }) => {
    const [status, setStatus] = useState(ExtractTrustDialogAcceptanceStatus() as TrustStatus);

    const setStatusCb = useCallback(
        (value: TrustStatus) => {
            setStatus(value);
            StoreTrustDialogAcceptanceStatusIfAbsent(value);
        },
        [setStatus],
    )

    return <TrustAcceptanceContext.Provider value={{ status, setStatus: setStatusCb }}>
        {children}
    </TrustAcceptanceContext.Provider>
}



export default TrustAcceptanceProvider;
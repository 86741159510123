
import config from "../../config";

export const fetchLocations = async (cardNumber: string,
    token: string,
    signal?: AbortSignal) => {

    const response = await fetch(
        `${config.api.domain.chargeTransactions}/locations/${cardNumber}`,
        {
            signal,
            mode: 'cors',
            headers: {
                'X-Recaptcha-Token': token,
                'Content-Type': 'application/json'
            }
        }
    );

   
    const data: Array<{ id: string, name: string }> = await response.json();
    const locations = data.map(({ id, name }) => ({ value: id, label: name }));

    return locations;

}

import { ISelectOption } from "../../common/components/Select/types";

export interface Address {
  addressLine: string,
  city: string,
  postalCode: string,
  country: string,
}
export interface Receipt {
  id: number,
  brandName: string,
  transactionDate: string,
  location: string,
  currency: string,
  totalCost: number
}

export interface RecaptchaVerifyResponse {
  success: boolean,
  score: number,
  action: string,
  hostname: string,
}

export type RetryError = {
  status: 429,
  retryAfter: number,
}

export type BadRequestError = {
  status: number,
  message?: string,
}

export type ResponseError = RetryError | BadRequestError;

export function isRetryError(error: ResponseError): error is RetryError {
  return error?.status === 429;
} 

export type ReceiptsStoreType = {
  items: Array<Receipt>,
  locations: Array<ISelectOption>,
  total: number,
  cardNumber: string | null,
  error: ResponseError | null
}
import { useEffect, useRef } from "react";


const elementsOverlap = (el1: Element, el2: Element) => {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    const getRect = (rect: DOMRect) => {
        const { top, bottom, left, right } = rect;
        return { top, bottom, left, right };
    }

    const rect1 = getRect(domRect1);
    const rect2 = getRect(domRect2);

    return !(
        rect1.top > rect2.bottom ||
        rect1.right < rect2.left ||
        rect1.bottom < rect2.top ||
        rect1.left > rect2.right
    );
}

export const useBlurWhenOverlap = () => {
    const dialogRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        const activeElement = document.activeElement;
        if (dialogRef.current == null || activeElement == null) {
            return;
        }

        if (activeElement.tagName.toLocaleLowerCase() === 'input'
            && elementsOverlap(activeElement, dialogRef.current)) {
            (activeElement as HTMLInputElement).blur();
        }
    }, [dialogRef]);

    return dialogRef

}

export default useBlurWhenOverlap;
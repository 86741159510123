import { useCallback } from "react";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { StoreCardNumberIfAbsent, ExtractStorageCardNumbers } from "../functions/SessionStorage/CardNumberStorage";
import { CardNumbersStored } from "../functions/SessionStorage/types";
import { TrustAcceptanceContext, TrustStatus } from "../../contexts/TrustAcceptanceContext";

export const useCardNumbersStorage = (): [CardNumbersStored[], (value: string) => void] => {

    const [cardNumbers, setCardNumbers] = useState<CardNumbersStored[]>([]);
    const { status } = useContext(TrustAcceptanceContext);

    useEffect(() => {
        if (status === TrustStatus.ACCEPTED) {
            setCardNumbers(ExtractStorageCardNumbers());
        }
    }, [status])

    const addCardNumber = useCallback((value: string) => {
        if (status === TrustStatus.ACCEPTED) {

            StoreCardNumberIfAbsent({
                lastFourDigits: value,
                storedDate: new Date().toLocaleString()
            });

            setCardNumbers(ExtractStorageCardNumbers());
        }

    }, [status,]);

    return [cardNumbers, addCardNumber]
}

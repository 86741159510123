import { TableColumnConfig } from "../Table";
import { Receipt } from "./types";

// const twoDigits = (n: number) => {
//     return n < 10 ? `0${n}` : `${n}`;
// }

const currencyToSymbolMap: Record<string, string> = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
}

export const receiptsTableConfig: TableColumnConfig<Receipt> = [
    {
        title: "Date / Time",
        key: "transactionDate",
        dataIndex: "transactionDate",
        width: 80,
        render: (value) => {
            const date = new Date(value.slice(0, -1));

            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

            //return dayjs(date).format("DD/MM/YYYY HH:mm");
            // return `${twoDigits(date.getDate())}/${twoDigits(date.getMonth() + 1)}/${twoDigits(date.getFullYear())}`
            //     + ` ${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`;
        }
    },
    {
        title: "Location",
        key: "location",
        dataIndex: "location",
        width: 100,
        render: (value) => {
            return <div className="line-clamp">{value}</div>;
        }
    },
    {
        title: "Amount",
        key: "totalCost",
        dataIndex: "totalCost",
        width: 50,
        align: "right",
        render: (value, r: Receipt, index) => {
            let formattedValue = '';
            try {
                formattedValue = new Intl.NumberFormat('en-GB',
                    { style: 'currency', currency: r.currency, currencyDisplay: 'narrowSymbol' }
                ).format(value);
            } catch (error) {
                const symbol = currencyToSymbolMap[r.currency] ?? r.currency;
                formattedValue = `${symbol}${value}`;
            }
            return <span>{formattedValue}</span>;
        }
    },
]

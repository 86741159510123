import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { TableColumnConfig } from "./types";


export const useColumnsSize = <T extends unknown>(prevColumns: TableColumnConfig<T>, width?: number): [TableColumnConfig<T>, React.RefObject<HTMLDivElement>, Record<string, number>] => {

    const tableWrapperRef = useRef<HTMLDivElement>(null);

    const [size, setSize] = useState<Record<string, number>>({})

    const columns: TableColumnConfig<T> = useMemo(() => {
        return prevColumns.map(col => {
            const { title: prevTitle, dataIndex } = col;

            const title = <span data-index={dataIndex}>{prevTitle}</span>
            return { ...col, title };
        })

    }, [prevColumns]);

    useEffect(() => {
        if (tableWrapperRef.current == null) {
            return;
        }
        const cells = tableWrapperRef.current.getElementsByClassName('rc-table-cell');
        if (!cells.length) {
            return;
        }
        const size: Record<string, any> = {};
        //@ts-ignore
        for (const cell of cells) {
            const dataCell = cell.querySelector('[data-index]');
            const dataIndex = dataCell?.getAttribute('data-index')
            if (dataIndex) {
                size[dataIndex] = cell.offsetWidth;
            }
        }
        setSize(size);

    }, [columns, tableWrapperRef, width]);

    return [columns, tableWrapperRef, size];
}
export const mergeArrays = <T>(
    existArray: Array<T>,
    newArray: Array<T>,
    startFrom?: number
): Array<T> => {
    const first = startFrom ?? 0;
    const initialValue = first === 0 ? [] : [...existArray];
    return newArray.reduce((agg, curr, index) => {
        agg[first + index] = curr;
        return agg;
    }, initialValue);
};
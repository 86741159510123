import { Reducer } from "react";
import { ISelectOption } from "./common/components/Select/types";
import { mergeArrays } from "./common/functions/arrayUtils";
import { Receipt, ResponseError } from "./Components/ReceiptsLst/types";
import config from "./config";
import { ReceiptsAction, ReceiptsActionType } from "./receiptsActions";

export interface ReceiptsState {
  cardNumber: string | null,
  location: ISelectOption;
  items: Array<Receipt>,
  locations: Array<ISelectOption>,
  total: number,
  error: ResponseError | null,
  isLoading: boolean,
}

export const receiptsReducer: Reducer<ReceiptsState, ReceiptsAction> = (state: ReceiptsState, { type, payload }: ReceiptsAction) => {

  switch (type) {
    case ReceiptsActionType.SET_CARD_NUMBER:
      return { ...state, cardNumber: payload };
    case ReceiptsActionType.SET_LOCATION:
      return { ...state, location: payload };
    case ReceiptsActionType.SET_RECEIPTS:
      const { data, skip, take } = payload;
      const items = mergeArrays(state.items, data, skip);
      const total = data.length < take ? items.length : items.length + config.app.pageSize;
      return { ...state, items, total, error: null }
    case ReceiptsActionType.SET_ERROR:
      return { ...state, error: payload }
    case ReceiptsActionType.SET_LOCATIONS:
      return { ...state, locations: payload }
    case ReceiptsActionType.CLEAR_ERROR:
      return { ...state, error: null }
    case ReceiptsActionType.SET_RECEIPTS_STATUS:
      const { isLoading } = payload;
      return { ...state, isLoading }
    default:
      return state;

  }
}
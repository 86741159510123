const Layout: React.FunctionComponent<any> = ({ children, className }) => {
  return <div className={`div-flex ${className ?? ''}`}>
    <div className="div-flex-1">
    </div>
    <div className="div-flex-3">
      {children}
    </div>
    <div className="div-flex-1">
    </div>
  </div>
}

export default Layout;
import { Button } from 'drax-design-system';

type ReceiptButtonProps = {
    onClick: () => void;
    disableButton: boolean;
}

const SearchCardFindReceiptsButton = (props: ReceiptButtonProps) => {

    return (<Button
        type="button"
        className={"consumption-date-picker__header-button"}
        data-testid="receipts-button"
        onClick={props.onClick}
        disabled={props.disableButton}
    >
        Find receipts
    </Button>);

}

export default SearchCardFindReceiptsButton;
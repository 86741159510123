import classNames from "classnames";
import { Checkbox } from "drax-design-system";
import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { ITable } from "./types";

export interface RowSelectionProps {
    enableSelectAll: boolean;
    selectedIds: string[];
    setSelectedIds: (ids: string[]) => void;
}

export const withRowSelection = <T extends { id: string | number }>(Component: React.ComponentType<ITable<T>>) => (props: ITable<T> & RowSelectionProps) => {

    const { columns: prevColumns, data } = props;
    const { enableSelectAll, selectedIds, setSelectedIds } = props;

    const [localSelectedIds, setLocalSelectedIds] = useState<string[]>(selectedIds);
    const [isSelectedAllData, setSelectedAllData] = useState<boolean>(false);

    useEffect(() => {
        setLocalSelectedIds(prev => {
            if (prev === selectedIds) {
                return prev;
            }
            if (prev.length !== selectedIds.length) {
                return selectedIds;
            }
            const isEqual = selectedIds.every(x => prev.includes(x));
            return isEqual ? prev : selectedIds;
        })

    }, [selectedIds, setLocalSelectedIds])

    useEffect(() => {
        if (!enableSelectAll) {
            return;
        }
        const shouldSelectData =
            !!localSelectedIds.length && localSelectedIds.length === data.length;
        setSelectedAllData(shouldSelectData);
        // eslint-disable-next-line
    }, [localSelectedIds, data]);

    useEffect(() => {
        setSelectedIds(localSelectedIds)
    }, [localSelectedIds, setSelectedIds]);

    const toggleSelectRow = (id: string) => {
        localSelectedIds.includes(id)
            ? setLocalSelectedIds(localSelectedIds.filter((f) => f !== id))
            : setLocalSelectedIds((prevSelectedData) => [...prevSelectedData, id]);
    };

    const selectAll = () => {
        setSelectedAllData((prev) => {
            if (prev) setLocalSelectedIds([]);
            else setLocalSelectedIds(data.map((row: any) => row.id));
            return !prev;
        });
    };

    const nextColumns = useMemo(() => {
        const renderCheckBox = (
            checked: boolean,
            handleChange: any,
        ) => {
            return (
                <Checkbox
                    className={classNames("p-table-checkbox", {
                        "p-table-checkbox--selected": checked,
                    })}
                    onChange={handleChange}
                    checked={checked}
                    label=""
                />
            );
        };

        const columnsWithActions = [...prevColumns];
        columnsWithActions.push({
            title: enableSelectAll ? renderCheckBox(isSelectedAllData, selectAll) : 'Select',
            //@ts-ignore
            dataIndex: "__select_all__",
            //@ts-ignore
            key: "__select_all__",
            width: 50,
            align: "center",
            render: (value, record) =>
                renderCheckBox( // TODO: check el
                    //@ts-ignore
                    localSelectedIds.includes(value?.id ?? record.id),
                    //@ts-ignore
                    toggleSelectRow.bind(null, value?.id ?? record.id),
                ),
        });

        return columnsWithActions;
    }, [prevColumns, isSelectedAllData, toggleSelectRow, selectAll]);


    return (<Component {...props} columns={nextColumns} />);
};

import { StylesConfig } from "react-select";

export const customStyles: StylesConfig<any, false> = {
  control: (base) => ({
    ...base,
    height: 46,
    borderRadius: 8,
    minHeight: 46,
    border: 0,
    boxShadow: 'none',
  }),
  dropdownIndicator: (base, { isFocused }) => {

    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        color: "#216ef2",
      };
    }
    return {
      ...base,
      ...additionalStyles,
      "&:hover": {
        color: "#216ef2",
      },
    }
  },
  container: (base, { isFocused }) => {
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        borderColor: '#216ef2',
        boxShadow: "none",
      };
    }
    return {
      ...base,
      borderColor: 'rgb(204, 204, 204)',
      borderRadius: 8,
      borderStyle: 'solid',
      borderWidth: 1,
      ...additionalStyles,
    }
  }

};


const fontStyles = {
  color: "#216EF2",
  fontWeight: 600,
  fontSize: "16px",
};

export const simpleSelectStyles: StylesConfig<any, false> = {
  control(base, props) {
    const { isFocused } = props;
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        border: "none",
        boxShadow: "none",
      };
    }

    return {
      ...base,
      cursor: "pointer",
      minWidth: "180px",
      backgroundColor: "transparent",
      border: "none",
      textOverflow: "ellipsis",
      ...additionalStyles,
    };
  },
  dropdownIndicator(base) {
    return {
      ...base,
      display: "none",
    };
  },
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  singleValue: (base, props) => {
    const styles = props.isDisabled ? {
      color: 'var(--accessible-mid-grey)'
    } : {}
    return {
      ...base,
      ...fontStyles,
      ...styles,
    };
  },
  placeholder: (base) => ({
    ...base,
    textOverflow: "ellipsis",
    whiteSpace: "pre",
    overflow: "hidden",
    maxWidth: "calc(100% - 16px)",
    width: "100%",
    ...fontStyles,
  }),
  menu(base) {
    return {
      ...base,
      borderRadius: "8px",
      zIndex: 10,
      marginTop: "0px",
      width: "170px",
      left: "-35px",
    };
  },
  menuList(base) {
    return {
      ...base,
      padding: 0,
    };
  },
  option(base, props) {
    const { isFocused } = props;
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        borderColor: "red",
        backgroundColor: "#F3F5F9",
      };
    }

    return {
      ...base,
      ...fontStyles,
      cursor: "pointer",
      textTransform: "capitalize",
      backgroundColor: "transparent",

      "&:hover": {
        backgroundColor: "#F3F5F9",
      },

      "&:first-of-type": {
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
      },
      "&:last-child": {
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      ...additionalStyles,
    };
  },
};

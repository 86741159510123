import { useState } from "react";
import { Events, sendEvent } from "../../common/functions/hjEvent";
import { useCardNumbersStorage } from "../../common/hooks/useCardNumbersStorage";
import SearchCardFindReceiptsButton from "./SearchCardFindReceiptsButton";
import SearchCardNumberDropdown from "./SearchCardNumberDropdown";

export interface SearchCardNumberProps {
    setCardNumber: (cardNumer: string) => void;
    hasData: boolean;
}

const SearchCardNumber = ({ setCardNumber, hasData }: SearchCardNumberProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const [inputValue, setInputValue] = useState<string>("");
    const [storedCardNumbers, addCardNumber] = useCardNumbersStorage();

    const onFindClick = async () => {

        setCardNumber(inputValue);
        addCardNumber(inputValue);
        sendEvent(Events.CARD_NUMBER_SEARCH_CLICK);
    }
    const isClosedAndHasData = !isOpen && hasData;

    return (
        <div className={`div-flex ${isClosedAndHasData ? '' : 'search-card-dropdown-container'}`}>
            <div className="search-card-dropdown">
                <SearchCardNumberDropdown
                    storedCardNumbers={storedCardNumbers}
                    inputValue={inputValue}
                    onInputChanged={(value: string) => {
                        setInputValue(value.trim());
                    }}
                    onMenuOpenToggle={setIsOpen}
                />
            </div>
            <div>
                <SearchCardFindReceiptsButton disableButton={inputValue.length !== 4} onClick={onFindClick} />
            </div>
        </div>
    );
}


export default SearchCardNumber;
import { AddToStorageIfAbsent, RetrieveFromStorage } from "./AddToStorageIfAbsent";
import { CardNumbersStored } from "./types";

var cardsKey = "cardNumbers"

export function StoreCardNumberIfAbsent(value: CardNumbersStored) {
    //let cardsKey = "cardNumbers";

    var existingCards = ExtractStorageCardNumbers();

    // //only save if its not already in storage
    // if (!existingCards.find(e => e.lastFourDigits === value.lastFourDigits))
    //     existingCards.push(value);
    AddToExistingCardsArray(existingCards, value);

    AddToStorageIfAbsent(cardsKey, JSON.stringify(existingCards));

    return true;
}

export function AddToExistingCardsArray(existingCards: CardNumbersStored[],
    value: CardNumbersStored) {

    if (existingCards == null)
        return null;

    if (value == null)
        return existingCards;

    if (!existingCards.find(e => e.lastFourDigits === value.lastFourDigits))
        existingCards.push(value);

    return existingCards;
}

export function ExtractStorageCardNumbers() {
    var response = new Array<CardNumbersStored>();
    var cardsInStorageString = RetrieveFromStorage(cardsKey);

    if (cardsInStorageString !== "") {
        let valuesArray: CardNumbersStored[] = JSON.parse(cardsInStorageString);
        response = valuesArray;
    }

    return response;
}
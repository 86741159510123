import RcTable from "rc-table";
import { ColumnType, CustomizeScrollBody } from "rc-table/lib/interface";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { ITable } from "./types";
import { useColumnsSize } from "./useColumnsSize";
import ResizeObserver, { SizeInfo } from 'rc-resize-observer';
import useDebounce from "../../common/hooks/useDebounce";
import ViewportList from "./ViewportList";
import useWindowSize from "../../common/hooks/useWindowSize";
import config from "../../config";
import { isDesktop } from "react-device-detect";

class LoadMorePlaceholder extends React.Component<{ onMount: any }, any> {
    componentDidMount() {
        this.props.onMount();
    }
    render() {
        return <div className="load-more-placeholder" />
    }
}
const OverScan = 20;

const TableEx = <T,>({
    data,
    itemCount,
    columns: prevColumns,
    loadMoreData,
}: ITable<T>) => {

    const [sizeInfoActual, setSizeInfo] = useState<SizeInfo>({ height: 100, width: 300 } as SizeInfo);
    const [windowWidth] = useWindowSize();

    const windowWidthPreviousRef = useRef(windowWidth);

    const isLoadingRef = useRef(false);

    const sizeInfo = useDebounce(sizeInfoActual, 50);

    const windowWidthDc = useDebounce(windowWidth, 25);

    const dataRows = useMemo(() => {

        if (typeof itemCount == 'number' && itemCount !== data.length) {
            const loadMore = () => {
                const start = data.length;
                const end = start + config.app.pageSize;
                if (!isLoadingRef.current) {
                    isLoadingRef.current = true
                    loadMoreData?.(start, end).then(_ => {
                        isLoadingRef.current = false;
                    });
                }
            }
            return [...data,
            () => {
                return <LoadMorePlaceholder onMount={loadMore} />
            },
            ];
        }
        return data;
    }, [data, itemCount]);

    useEffect(() => {
        if (windowWidthPreviousRef.current > windowWidthDc) {
            const diff = windowWidthPreviousRef.current - windowWidthDc;
            setSizeInfo(prev => {
                return { ...prev, width: prev.width - diff }
            })
        }
        windowWidthPreviousRef.current = windowWidthDc;
    }, [windowWidthDc])

    const width = sizeInfo.width;
    const [columns, tableWrapperRef, size] = useColumnsSize(prevColumns, width);

    const renderVirtualList: CustomizeScrollBody<any> = (tableData, { scrollbarSize }) => {

        const Row = (record: any, rowIndex: number, arr: any[]) => {
            let cellElements = null;

            if (typeof record == 'function') {
                return (
                    <div
                        className="rc-table-row rw-row">
                        <div key={`${String(rowIndex)}`} className={`rc-table-cell rw-cell `}>
                            {record()}
                        </div>

                    </div>
                )
            } else {
                cellElements = columns.map((colCfg, colIndex) => {
                    const { render: cellRender, dataIndex } = colCfg;
                    let cell: any = null;
                    if (dataIndex != null && record != null) {
                        const key = dataIndex as string;
                        const value = record[key];
                        if (typeof cellRender == 'function') {
                            cell = cellRender(value, record, rowIndex);
                        } else {
                            cell = value;
                        }
                    }

                    const width = size[dataIndex as string];
                    const cellStyle: React.CSSProperties = { width };
                    const alignClass = colCfg?.align ?? ''
                    return (
                        <div key={`cell.${String(dataIndex)}.${colIndex}`} style={cellStyle} className={`rc-table-cell rw-cell ${alignClass}`}>{cell}</div>
                    )
                });
            }
            return (
                <div key={`row.${rowIndex}.${record.id}`} data-key={`row.${rowIndex}.${record.id}`}
                    className="rc-table-row rw-row">
                    {cellElements}
                </div>
            )
        };

        return (<ViewportList items={tableData as any[]} overscan={OverScan}>
            {Row}
        </ViewportList>
        );
    };

    return (
        <ResizeObserver onResize={setSizeInfo}>
            <div ref={tableWrapperRef} style={{ paddingBottom: isDesktop ? 125 : undefined }} >
                <RcTable
                    style={{ width }}
                    data={dataRows}
                    columns={columns as any as Array<ColumnType<any>>}
                    className="p-table"
                    scroll={{
                        y: 50,
                    }}
                    rowKey={(row) => row.id}
                    emptyText={null}
                    components={{
                        body: renderVirtualList,
                    }}
                />
            </div>
        </ResizeObserver>
    );
};

export default TableEx;

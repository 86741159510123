import React from "react";
import { Select } from "../../common/components/Select";
import { ISelectOption } from "../../common/components/Select/types";
import { CardNumbersStored } from "../../common/functions/SessionStorage/types";
import useWindowSize from "../../common/hooks/useWindowSize";

type SearchCardNumberDropdownProps = {
  storedCardNumbers: Array<CardNumbersStored>;
  inputValue: string;
  onInputChanged: (value: string) => void;
  onMenuOpenToggle?: (isOpen: boolean) => void;
}


const SearchCardNumberDropdown = (props: SearchCardNumberDropdownProps) => {

  const { inputValue: value, storedCardNumbers } = props;
  const { onInputChanged, onMenuOpenToggle } = props;

  const [width] = useWindowSize();

  function checkInputValue() {

    if (value.length === 4) {
      const label = (width < 400 ? "**** " : "**** **** **** ") + value;
      return { value, label } as ISelectOption
    }

    return null;
  }

  function convertStoredCardNumbersForDropdown() {

    const response: Array<ISelectOption> = storedCardNumbers
      .sort((x, y) => x.storedDate.localeCompare(y.storedDate))
      .map((item, i) => {

        return {
          value: item.lastFourDigits,
          label: "**** **** **** " + item.lastFourDigits
        };
      })

    return response;
  }



  return (
    <Select
      options={convertStoredCardNumbersForDropdown()}
      showIcon={false}
      value={checkInputValue()}
      placeholder="Enter last 4 digits or select previous card"
      onSelectChange={(el: ISelectOption) => onInputChanged(el.value)}
      isClearable={false}
      data-testid="cardNumber-dropdown"
      autoFocus={true}
      onInputChanged={onInputChanged}
      onMenuOpenToggle={onMenuOpenToggle}
    />
  );

}

export default SearchCardNumberDropdown;
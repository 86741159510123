import { useEffect, useMemo, useReducer, useState } from 'react';
import { receiptsTableConfig } from './Components/ReceiptsLst/tableConfig';
import { ErrorContainer } from './Components/ErrorContainer';

import SearchCardNumber from './Components/SearchCardNumber/SearchCardNumber';
import Layout from './common/components/Layout';
import config from './config';
import ErrorMessage from './Components/ErrorMessage';
import Title from './Components/Title';
import ReceiptsDownload from './Components/ReceiptsDownload';
import TrustDialog from './Components/Dialog/TrustDialog';
import Table, { withRowSelection } from './Components/Table';

import "./styles/index.scss";

import { SimpleSelect } from './common/components/Select';
import { FooterLayout } from './common/components/Footer';
import { receiptsReducer, ReceiptsState } from './receiptsReducer';
import { useCallback } from 'react';
import { ReceiptsActionType, useDownloadReceipt, useFetchLocations, useFetchReceipts } from './receiptsActions';


const ALL_VALUE = '__ALL__';
const ALL_ITEM = { value: ALL_VALUE, label: 'All' };

const initialState = {
  cardNumber: null,
  location: ALL_ITEM,
  items: [],
  locations: [],
  total: 0,
  error: null,
  isLoading: true
} as ReceiptsState;

const TableWithCheckbox = withRowSelection(Table);

const Receipts = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [store, dispath] = useReducer(receiptsReducer, initialState);
  const [retryToken, setRetryToken] = useState(Date.now());

  const { cardNumber, location } = store;


  const setCardNumber = useCallback((payload) => {
    dispath({ type: ReceiptsActionType.SET_CARD_NUMBER, payload })
    dispath({ type: ReceiptsActionType.SET_LOCATION, payload: ALL_ITEM })
  }, [dispath]);


  const clearError = useCallback(() => {
    dispath({ type: ReceiptsActionType.CLEAR_ERROR, payload: null })
  }, [dispath]);

  const setLocation = useCallback((payload) => {
    dispath({ type: ReceiptsActionType.SET_LOCATION, payload })
  }, [dispath]);

  const locationId = location == null || location.value === ALL_VALUE ? null : location.value;
  const fetchData = useFetchReceipts(dispath);
  const fetchLocations = useFetchLocations(dispath);
  const downloadReceipts = useDownloadReceipt(dispath);

  useEffect(() => {
    if (!cardNumber) {
      return;
    }
    const controller = new AbortController();
    setSelectedIds([]);
    fetchData(cardNumber, locationId, 0, config.app.pageSize, controller.signal);
    return () => {
      controller.abort();
    }
  }, [fetchData, cardNumber, locationId, retryToken]);

  useEffect(() => {
    if (!cardNumber) {
      return;
    }
    const controller = new AbortController();
    fetchLocations(cardNumber, controller.signal)
    return () => {
      controller.abort();
    }
  }, [fetchLocations, cardNumber, retryToken]);

  const locationOptions = useMemo(() => {
    return [ALL_ITEM, ...store.locations];
  }, [store.locations])

  const noError = store.error == null;
  const hasData = store.items.length > 0;
  const showTable = store.cardNumber && noError && hasData;
  const showNoData = store.cardNumber && noError && !hasData && store.isLoading === false;
  const isLocationDisabled = store.locations?.length === 0;

  return <>
    <Layout>
      <h1 className='receipts-header-label'>
        Payment card or virtual number (last 4 digits)
      </h1>
      <SearchCardNumber setCardNumber={setCardNumber} hasData={showTable || showNoData || !noError} />
      <div className="receipts-table-container">
        <ErrorContainer error={store.error} clearError={() => {
          clearError();
          setRetryToken(Date.now());
        }} />
        {
          showTable && <>
            <div className="receipts-table-header">
              <Title title='Receipts' />
              <SimpleSelect options={locationOptions}
                value={location}
                isDisabled={isLocationDisabled}
                label={"Location"}
                onSelectChange={setLocation} />
              <ReceiptsDownload ids={selectedIds} onDownload={downloadReceipts} />
            </div>
            <TableWithCheckbox
              enableSelectAll={false}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              data={store.items}
              itemCount={store.total}
              loadMoreData={(start, stop) => {
                return fetchData(cardNumber!, locationId, start, stop - start);
              }}
              columns={receiptsTableConfig as any}
            />
          </>
        }
        {
          showNoData && <ErrorMessage
            title="No Data"
            description="Please reload the page or make another selection"
          />
        }
      </div>
      <TrustDialog />
    </Layout>
    <FooterLayout store={store} />
  </>
}


export default Receipts;
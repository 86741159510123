import { Dispatch, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ISelectOption } from './common/components/Select/types';
import { downloadReceipt } from './Components/ReceiptsLst/downloadReceipt';
import { fetchLocations } from './Components/ReceiptsLst/fetchLocations';
import { fetchReceipts } from './Components/ReceiptsLst/fetchReceipts';
import { getErrorFromResponse } from './Components/ReceiptsLst/getErrorFromResponse';
import { Receipt, ResponseError } from './Components/ReceiptsLst/types';

export enum ReceiptsActionType {
  SET_CARD_NUMBER,
  SET_LOCATION,
  SET_RECEIPTS,
  SET_RECEIPTS_STATUS,
  SET_ERROR,
  SET_LOCATIONS,
  CLEAR_ERROR,
}

export type SetCardNumberAction = {
  type: ReceiptsActionType.SET_CARD_NUMBER,
  payload: string
}

export type SetLocationAction = {
  type: ReceiptsActionType.SET_LOCATION,
  payload: ISelectOption
}

export type SetLocationsAction = {
  type: ReceiptsActionType.SET_LOCATIONS,
  payload: ISelectOption[]
}

export type ClearErrorAction = {
  type: ReceiptsActionType.CLEAR_ERROR,
  payload: any
}

export type SetReceiptsAction = {
  type: ReceiptsActionType.SET_RECEIPTS,
  payload: {
    data: Receipt[],
    skip: number,
    take: number,
  }
}

export type SetReceiptsStatusAction = {
  type: ReceiptsActionType.SET_RECEIPTS_STATUS,
  payload: {
    isLoading: boolean
  }
}

export type SetErrorAction = {
  type: ReceiptsActionType.SET_ERROR,
  payload: ResponseError
}

export type ReceiptsAction = SetCardNumberAction
  | SetLocationAction
  | SetLocationsAction
  | SetErrorAction
  | SetReceiptsAction
  | SetReceiptsStatusAction
  | ClearErrorAction;

type FetchReceipts = (cardNumber: string, locationId: string | null, skip: number, take: number, signal?: AbortSignal) => Promise<any>;

export const useFetchReceipts = (dispatch: Dispatch<ReceiptsAction>): FetchReceipts => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const fetchReceiptsCb = useCallback(async (cardNumber: string, locationId: string | null, skip: number, take: number, signal?: AbortSignal) => {
    try {
      const token = await executeRecaptcha!();
      dispatch({ type: ReceiptsActionType.SET_RECEIPTS_STATUS, payload: { isLoading: true } });
      const response = await fetchReceipts(cardNumber, locationId, { skip, take }, token, signal);
      const { data } = response!;
      dispatch({ type: ReceiptsActionType.SET_RECEIPTS, payload: { data, skip, take } });
      dispatch({ type: ReceiptsActionType.SET_RECEIPTS_STATUS, payload: { isLoading: false } });
    } catch (err: any) {
      let error = { status: 500 };
        if (err instanceof Response) {
          error = await getErrorFromResponse(err);
        }
      dispatch({ type: ReceiptsActionType.SET_ERROR, payload: error });
    }

  }, [dispatch, executeRecaptcha]);

  return fetchReceiptsCb;
}


type FetchLocations = (cardNumber: string, signal?: AbortSignal) => Promise<any>;
export const useFetchLocations = (dispatch: Dispatch<ReceiptsAction>): FetchLocations => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const fetchLocationsCb = useCallback(async (cardNumber: string, signal?: AbortSignal) => {
    try {
      const token = await executeRecaptcha!();
      const locations = await fetchLocations(cardNumber, token, signal);

      dispatch({ type: ReceiptsActionType.SET_LOCATIONS, payload: locations });

    } catch (err: any) {
    }

  }, [dispatch, executeRecaptcha]);


  return fetchLocationsCb;
}


type DownloadReceipts = (ids: string[], signal?: AbortSignal) => Promise<any>;
export const useDownloadReceipt = (dispatch: Dispatch<ReceiptsAction>): DownloadReceipts => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const downloadReceiptsCb = useCallback(async (ids: string[], signal?: AbortSignal) => {
    const successIds = [];
    for (const id of ids) {
      try {
        const token = await executeRecaptcha!();
        await downloadReceipt(id, token);
        successIds.push(id);
      } catch (err) {
        let error = { status: 500 };
        if (err instanceof Response) {
          error = await getErrorFromResponse(err);
        }
        dispatch({ type: ReceiptsActionType.SET_ERROR, payload: error });
        break;
      }
    }

  }, [dispatch, executeRecaptcha])


  return downloadReceiptsCb;
}

export enum Events {
    TRUST_CONSENT_SHOW = "TRUST_CONSENT_SHOW",
    TRUST_CONSENT_YES = "TRUST_CONSENT_YES",
    TRUST_CONSENT_NO = "TRUST_CONSENT_NO",
    ERROR_RATE_LIMIT = "ERROR_RATE_LIMIT",
    ERROR_BOT = "ERROR_BOT",
    ERROR_GENERAL = "ERROR_GENERAL",
    CARD_NUMBER_SEARCH_CLICK = "CARD_NUMBER_SEARCH_CLICK",
    RECEIPT_DOWNLOAD_CLICK = "RECEIPT_DOWNLOAD_CLICK",
}

export const sendEvent = (ev: Events) => {
    ///@ts-ignore
    const hj: any = window.hj ?? Function;
    hj('event', ev);
}

export const sendDownloadEvent = (n: number) => {
    ///@ts-ignore
    sendEvent(`RECEIPT_DOWNLOAD_COUNT_${n}`);
}
import { ReceiptsStoreType } from '../../../Components/ReceiptsLst/types';
import config from '../../../config';
import Footer from './Footer';
import { isDesktop } from "react-device-detect";

export const FooterLayout: React.FC<{ store: ReceiptsStoreType }> = ({ store }) => {

  return <Footer className={isDesktop ? 'footer-fixed' : ''} version={config.version || "x.x.x"} />

}

export default FooterLayout;
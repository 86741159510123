import React from "react";
import { components, } from "react-select";
import "./styles.scss";

const { SingleValue } = components;

export const CustomSingleValue = (props: any) => {
  const { getStyles: prevGetStyles } = props;

  const getStyles = (propertyName: any, styleProps: any) => {
    if (propertyName === "singleValue") {
      const singleValueStyles = prevGetStyles(propertyName, styleProps);
      const isFocused: boolean = (props.selectProps as any).isFocused;
      if (isFocused) {
        //singleValueStyles["opacity"] = 0.5;
        singleValueStyles["color"] = "hsl(0, 0%, 50%)";
      }

      return singleValueStyles;
    }

    return prevGetStyles(propertyName, styleProps);
  }

  return <SingleValue {...props} getStyles={getStyles} />;
};

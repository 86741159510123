import config from "../../config";
import { getErrorFromResponse } from "./getErrorFromResponse";
import { Receipt } from "./types";

export const fetchReceipts = async (cardNumber: string,
    locationId: string | null,
    page: { skip: number, take: number },
    token: string,
    signal?: AbortSignal) => {
    const { skip, take } = page;
    let url = `${config.api.domain.chargeTransactions}/summary/${cardNumber}?skip=${skip}&take=${take}`;
    if (locationId != null && locationId.length) {
        url += `&location=${encodeURIComponent(locationId)}`;
    }
    const response = await fetch(url,
        {
            signal,
            mode: 'cors',
            headers: {
                'X-Recaptcha-Token': token,
                'Content-Type': 'application/json'
            }
        }
    );

    if (!response.ok) {
        const error = await getErrorFromResponse(response);
        throw error;
    }

    const data: Receipt[] = await response.json();

    return { data, skip, take };
}
